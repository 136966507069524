import React from "react";
import { Input } from "antd";
export default function Index(props) {
  if (props.type === "text") {
    return (
      <Input
        placeholder={props.placeholder}
        style={{
          width: props.width,
          height: props.height,
          fontFamily: props.fontFamily,
          fontSize: props.fontSize,
          color: props.color,
        }}
      />
    );
  } else if (props.type === "password") {
    return (
      <Input.Password
        placeholder={props.placeholder}
        style={{
          width: props.width,
          height: props.height,
          fontFamily: props.fontFamily,
          fontSize: props.fontSize,
          color: props.color,
          marginTop: props.marginTop ? props.marginTop : 0,
        }}
      />
    );
  } else if (props.type === "number") {
    return (
      <Input
        type={"number"}
        placeholder={props.placeholder}
        style={{
          width: props.width,
          height: props.height,
          fontFamily: props.fontFamily,
          fontSize: props.fontSize,
          color: props.color,
          marginTop: props.marginTop ? props.marginTop : 0,
        }}
      />
    );
  } else if (props.type === "textarea") {
    return (
      <Input.TextArea
        placeholder={props.placeholder}
        style={{
          width: props.width,
          height: props.height,
          fontFamily: props.fontFamily,
          fontSize: props.fontSize,
          color: props.color,
          marginTop: props.marginTop ? props.marginTop : 0,
        }}
      />
    );
  }
}
