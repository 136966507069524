import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Text from "../../components/text";
import "./home.css";
import { fonts } from "../../theme";
import Divider from "../../components/divider";
import Image from "../../components/image";
import Tag from "../../components/tag";
export default function EventAttendees() {
  const [attendees, setAttendees] = useState([
    {
      id: 1,
      status: "Hosting",
      name: "Rajat Singh",
      profileImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWHjMzM5qzBjS64SJEIyCQkLgXODzDLznFPOt54tmyPNXP3BQ78_AN83FAlbeGujmuPCg&usqp=CAU",
    },
    {
      id: 2,
      status: "Going",
      name: "Austin Tse",
      profileImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMBF7GiWBnuwHZEoUkGAktUZR91Ge2bVJ9rhYg7-YRz0M7tdM-8Os4tSZno4Jd6j5p9p8&usqp=CAU",
    },
    {
      id: 3,
      status: "Invited",
      name: "Andrea Rodriguez",
      profileImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcrIPUVHe81YZpOiRUNwRq32b7QEpEVP6YeuAImz3FaOtVYPTNNkRveATsieLpH2_kr4g&usqp=CAU",
    },
    {
      id: 4,
      status: "Invited",
      name: "Conner Johannigman",
      profileImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNlXZlMguDa-0yDfWZbxjbWqSu4TCEK8d-gEDkQWIUK3Ox1Sx2VnDyYy6oKpWjw0ALTXY&usqp=CAU",
    },
    {
      id: 5,
      status: "Invited",
      name: "Mariana Sanchez",
      profileImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl8m-FbIDRnERQ0CB8HmG2orcp_UnoGjdsB6UHs-XecVVrLLMairYWP2KOBQIpQ6d2cts&usqp=CAU",
    },
    {
      id: 6,
      status: "Invited",
      name: "Siddhant Benadikar",
      profileImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRscMgflIRO38TCPjrLqkbMRK7gL9HHuS_Rw3Un5koCFokRj9SrjanLfnU12iBjgtuOZTU&usqp=CAU",
    },
    {
      id: 7,
      status: "Invited",
      name: "Danny Yohannes",
      profileImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEyauOUJhB_lNxsF-KpNtHfKq_PyqLR4SnovlGUyeJcYsJ7sjx_nt2U1Z0jVAVU-4XBdM&usqp=CAU",
    },
    {
      id: 7,
      status: "Invited",
      name: "Mark Karelin",
      profileImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_F8C4HIpNYVo0MdFY8Jr4_IM_m4lXTLaUw2JeNHMT-NXd3Mpi_c4gIY9phccoEi-yjyk&usqp=CAU",
    },
  ]);
  return (
    <Container className={"event-attendees-container"}>
      <Container className={"event-attendees"}>
        <div className={"event-attendees-header"}>
          <Text
            text={"Attendees (" + attendees.length + ")"}
            fontFamily={fonts.ROBOTO.REGULAR}
            fontSize={"0.875rem"}
            fontWeight={500}
            color={"rgba(107, 114, 128)"}
          />
        </div>
        <Divider marginTop={"-5px"} />
        {attendees.map((item) => {
          return (
            <div className={"attendees-item"} key={item.id}>
              <div className={"attendees-item-info"}>
                <Image
                  src={item.profileImage}
                  width={"40px"}
                  height={"40px"}
                  borderRadius={"50%"}
                />
                <span className={"attendees-item-name"}>{item.name}</span>
              </div>
              <Tag
                text={item.status}
                background={
                  item.status === "Hosting"
                    ? "rgba(219, 234, 254)"
                    : item.status === "Going"
                    ? "rgba(209, 250, 229)"
                    : "rgba(254, 243, 199)"
                }
                color={
                  item.status === "Hosting"
                    ? "rgba(30, 64, 17)"
                    : item.status === "Going"
                    ? "rgba(6, 95, 70)"
                    : "rgba(146, 64, 1)"
                }
                borderRadius={"20px"}
              />
            </div>
          );
        })}
      </Container>
    </Container>
  );
}
