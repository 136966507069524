import React from "react";
import { Image } from "react-bootstrap";
export default function index(props) {
  return (
    <Image
      fluid={props.fluid ? props.fluid : false}
      src={props.src}
      style={{
        width: props.width,
        height: props.height,
        borderRadius: props.borderRadius ? props.borderRadius : null,
        boxShadow: props.boxShadow ? props.boxShadow : null,
        filter: props.filter ? props.filter : null,
        position: props.position ? props.position : null,
        top: props.top ? props.top : null,
      }}
      alt={props.alt}
    />
  );
}
