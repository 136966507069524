import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./home.css";
import { FaShareAlt } from "react-icons/fa";
import Button from "../../components/button";
import Text from "../../components/text";
import { fonts } from "../../theme";
import { HiOutlineLink } from "react-icons/hi";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
export default function HomeShareBox() {
  const [copyMessage, setCopyMessage] = useState("Tap to copy share link");
  const [textToCopy, setTextToCopy] = useState(
    "https://events.joingather.app/"
  );
  const handleCopy = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopyMessage("Copied!");
        setTimeout(() => setCopyMessage("Tap to copy share link"), 2000); // Clear message after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        setCopyMessage("Failed to copy");
      });
  };
  return (
    <>
      <Container
        className={"home-share-box"}
        fluid={isMobile ? true : false}
        style={{
          width: isMobile ? "95%" : "59.3%",
          marginTop: isMobile ? "10px" : "40px",
        }}
      >
        {isMobile ? (
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col md={11} style={{ padding: 0 }} onClick={() => handleCopy()}>
              <div className={"home-share-box-share-content"}>
                <div>
                  <span marginLeft={"5px"} className={"copy-message"}>
                    {copyMessage}
                  </span>

                  <span className={"share-link"}>
                    https://events.joingather.app/
                  </span>
                </div>
                <div className={"link-icon"}>
                  <HiOutlineLink size={25} color={"#9ca3af"} />
                </div>
              </div>
            </Col>
            <BrowserView>
              <Col md={1}>
                <div className={"home-share-box-share-btn"}>
                  <Button
                    text={
                      <FaShareAlt
                        color={"#9ca3af"}
                        size={20}
                        border={"0px solid red"}
                      />
                    }
                  />
                </div>
              </Col>
            </BrowserView>
          </Row>
        ) : (
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col md={11} style={{ padding: 0 }} onClick={() => handleCopy()}>
              <div className={"home-share-box-share-content"}>
                <div>
                  <span marginLeft={"5px"} className={"copy-message"}>
                    {copyMessage}
                  </span>

                  <span className={"share-link"}>
                    https://events.joingather.app/
                  </span>
                </div>
                <div className={"link-icon"}>
                  <HiOutlineLink size={25} color={"#9ca3af"} />
                </div>
              </div>
            </Col>
            <Col md={1}>
              <div className={"home-share-box-share-btn"}>
                <Button
                  text={
                    <FaShareAlt
                      color={"#9ca3af"}
                      size={20}
                      border={"0px solid red"}
                    />
                  }
                />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
