export default {
  ROBOTO: {
    THIN: `"Roboto", sans-serif`,
    LIGHT: `"Roboto", sans-serif`,
    REGULAR: `Roboto", sans-serif`,
    MEDIUM: `"Roboto", sans-serif`,
    BOLD: `"Roboto", sans-serif`,
  },
  POPPINS: {
    THIN: `"Poppins", serif`,
    LIGHT: ``,
  },
};
