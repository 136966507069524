import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "../../components/image";
import "./footer.css";
import { fonts, images } from "../../theme";
import Title from "../../components/title";
import Text from "../../components/text";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
export default function Index() {
  const [appLink, setAppLink] = useState([
    {
      id: 1,
      link: "#",
      icon: images.apple,
    },
    {
      id: 2,
      link: "#",
      icon: images.google,
    },
  ]);

  const [footerLink, setFooterLink] = useState([
    {
      id: 1,
      title: "Contact Us",
      link: "#",
    },
    {
      id: 2,
      title: "Terms",
      link: "#",
    },
    {
      id: 3,
      title: "Privacy",
      link: "#",
    },
  ]);
  return (
    <Container fluid className={"footer-container"}>
      {isMobile ? (
        <Row>
          <Col>
            <div className={"footer-logo-holder"}>
              <Image
                src={images.logo}
                className={"footer-logo"}
                width={"20%"}
              />
            </div>
            <div
              className={"footer-content"}
              style={{
                textAlign: "center",
              }}
            >
              <Title
                text={`Be among the first to try Gather and start connecting.`}
                fontFamily={fonts.ROBOTO.MEDIUM}
                fontSize={"22px"}
                color={"rgb(255, 255, 255)"}
              />
            </div>
            <div
              className={"footer-app-links"}
              style={{
                width: isMobile ? "100%" : "80%",
              }}
            >
              {appLink.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={"app-links"}
                    style={{
                      width: isMobile ? "100%" : "30%",
                    }}
                  >
                    <div className={"app-icons"}>
                      <Image
                        src={item.icon}
                        width={isMobile ? "120px" : "120px"}
                        height={isMobile ? "40px" : "40px"}
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className={"footer-links"}
              style={{
                marginTop: "10px",
                width: isMobile ? "100%" : "85%",
                marginLeft: isMobile ? "0%" : "5%",
              }}
            >
              <ul
                className={"horizontal-list"}
                style={{ gap: isMobile ? "6px" : "15px" }}
              >
                {footerLink.map((item) => {
                  return (
                    <li className={"footer-link-li"}>
                      <a href="">&#8226; {item.title}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={"footer-content"}>
              <Text
                text={`© Copyright 2024. SoDuo Connections Corporation`}
                fontFamily={fonts.ROBOTO.REGULAR}
                fontSize={"16px"}
                color={"rgb(255, 255, 255)"}
              />
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col></Col>
          <Col>
            <div className={"footer-logo-holder"}>
              <Image
                src={images.logo}
                className={"footer-logo"}
                width={"20%"}
              />
            </div>
            <div
              className={"footer-content"}
              style={{
                textAlign: "center",
              }}
            >
              <Title
                text={`Be among the first to try Gather and start connecting.`}
                fontFamily={fonts.ROBOTO.MEDIUM}
                fontSize={"22px"}
                color={"rgb(255, 255, 255)"}
              />
            </div>
            <div
              className={"footer-app-links"}
              style={{
                width: isMobile ? "100%" : "80%",
              }}
            >
              {appLink.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={"app-links"}
                    style={{
                      width: isMobile ? "100%" : "30%",
                    }}
                  >
                    <div className={"app-icons"}>
                      <Image
                        src={item.icon}
                        width={isMobile ? "90px" : "120px"}
                        height={isMobile ? "30px" : "40px"}
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className={"footer-links"}
              style={{
                marginTop: "10px",
                width: isMobile ? "100%" : "85%",
                marginLeft: isMobile ? "0%" : "5%",
              }}
            >
              <ul
                className={"horizontal-list"}
                style={{ gap: isMobile ? "2px" : "15px" }}
              >
                {footerLink.map((item) => {
                  return (
                    <li className={"footer-link-li"}>
                      <a href=""> {item.title}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={"footer-content"}>
              <Text
                text={`© Copyright 2024. SoDuo Connections Corporation`}
                fontFamily={fonts.ROBOTO.REGULAR}
                fontSize={"16px"}
                color={"rgb(255, 255, 255)"}
              />
            </div>
          </Col>
          <Col></Col>
        </Row>
      )}
    </Container>
  );
}
