import React from "react";
import Home from "../Pages/Home";
import Register from "../Pages/Register";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
export default function Index() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/register" element={<Register />}></Route>
      </Routes>
    </Router>
  );
}
