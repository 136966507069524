import React from "react";
import { Button } from "antd";
export default function Index(props) {
  return (
    <Button
      color={props.color}
      style={{
        width: props.width,
        height: props.height,
        fontFamily: props.fontFamily,
        fontSize: props.fontSize,
        color: props.color,
        fontWeight: props.fontWeight,
        borderRadius: props.borderRadius ? props.borderRadius : null,
        border: props.border ? props.border : null,
        borderWidth: props.borderWidth ? props.borderWidth : 0,
        outline: props.outline ? props.outline : null,
        boxShadow: props.boxShadow ? props.boxShadow : null,
        marginTop: props.marginTop ? props.marginTop : 0,
        background: props.background,
      }}
      variant={props.variant ? props.variant : "filled"}
    >
      {props.text}
    </Button>
  );
}
