import React from "react";

export default function Index(props) {
  return (
    <h3
      style={{
        fontFamily: props.fontFamily,
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
        color: props.color,
        marginLeft: props.marginLeft ? props.marginLeft : 0,
        marginRight: props.marginRight ? props.marginRight : 0,
        marginBottom: props.marginBottom ? props.marginBottom : 0,
        marginTop: props.marginTop ? props.marginTop : 0,
      }}
    >
      {props.text}
    </h3>
  );
}
