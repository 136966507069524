import logo from "../assets/images/logo.png";
import dummy from "../assets/images/dummy.jpg";
import apple_badge from "../assets/images/apple-app-store-badge.svg";
import google_badge from "../assets/images/google-play-badge.svg";
export default {
  logo: logo,
  dummy: dummy,
  apple: apple_badge,
  google: google_badge,
};
